.icon {
  display: inline-block;
}

.icon.fast-right-spinner {
  animation: icon-spin-r 1s infinite linear;
}

.icon.normal-right-spinner {
  animation: icon-spin-r 2s infinite linear;
}

.icon.slow-right-spinner {
  animation: icon-spin-r 3s infinite linear;
}

.icon.fast-left-spinner {
  animation: icon-spin-l 1s infinite linear;
}

.icon.normal-left-spinner {
  animation: icon-spin-l 2s infinite linear;
}

.icon.slow-left-spinner {
  animation: icon-spin-l 3s infinite linear;
}

@-webkit-keyframes icon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spin-r {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 48%;
  }

  100% {
    transform: rotate(359deg);
    transform-origin: 50% 48%;
  }
}

@-webkit-keyframes icon-spin-l {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes icon-spin-l {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
