// Navbar alignment
//
// Classes for helping space your content when certain navbars are present.

// Fixes the padding of containers inside of navs
.navbar > .container-fluid {
  margin-left: -$navbar-padding-x;
  margin-right: -$navbar-padding-x;
}

// When a navbar is fixed to the top, add some padding
.with-top-navbar {
  padding-top: 49px;
}

// Fix up counters in the navbar
.navbar-nav {
  > li > a > .badge {
    margin-left: 5px;
  }
}

.navbar-brand > img {
  vertical-align: text-top;
}

.navbar-brand-icon {
  float: left;
  margin-right: 5px;
}

// Emphasized navbar (puts a special background-color on it)
.navbar-default {
  .navbar-brand-emphasized {
    &,
    &:hover,
    &:focus {
      background-color: $navbar-border;
    }
  }
}

.navbar-dark {
  .navbar-brand-emphasized {
    &,
    &:hover,
    &:focus {
      color: $white-color;
      background-color: $navbar-inverse-border;
    }
  }
}

.navbar-padded .navbar-header {
  padding-left: 5px;
  padding-right: 5px;
}

@include media-breakpoint-up(sm) {
  .navbar-padded {
    padding-top: $spacer-y;
    padding-bottom: $spacer-y;
  }
}

// Simpler navbar form controls
.navbar-form-control {
  border: 0;
  box-shadow: none;
}

.navbar-dark {
  .navbar-form-control {
    background-color: lighten($navbar-inverse-bg, 7%);

    &:focus {
      color: $navbar-inverse-bg;
      background-color: $white-color;
      box-shadow: 0 0 6px rgba(255,255,255,0.5);
    }
  }
}

.navbar-nav {
  > .active > a {
    &,
    &:hover,
    &:focus {
      font-weight: $navbar-nav-active-font-weight; // inherit
    }
  }
}

.navbar-btn-avatar,
.navbar-btn-avatar:active,
.navbar-btn-avatar:hover,
.navbar-btn-avatar:focus,
.navbar-btn-avatar:hover:active,
.navbar-btn-avatar:active:focus {
  padding: 0;
  border: 0;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
}

// TODO:
// - Figure out if this is a pervasive typo or just an error here?
// - Figure out how to size this since .navbar uses padding now
.navbar-btn-avatar img {
  height: 35px;
  width: 35px;
}

// port of old header
.navbar-header {
  @include clearfix;
  @include media-breakpoint-up(md) {
    float: left;
  }
}
